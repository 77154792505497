@import './../../../vars.scss';


.content-pos {
  padding: 5em;
  background-image: url('/img/010_dash-8.png');
  background-position: 170% 50%;
  background-repeat: no-repeat;
  background-size: 70%;

  .container-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    align-items: center;

    @media (max-width: 1024px) {
      & {
        grid-template-columns: 100%;
      }
    }

    .img-compu {
      width: 100%;
    }
  }
}

.pos {
  padding: 3rem 3rem;
  &-header {
    line-height: 1;
    align-items: center;
    display: flex;

    @media (max-width: 500px) {
      & {
        display: block;
      }
    }

    &__logo {
      margin-right: 2rem;

      @media (max-width: 500px) {
        & {
          text-align: center;
        }
      }

      img {
        width: 6rem;
      }
    }

    &__title {
      font-size: 1.7rem;

      @media (max-width: 500px) {
        & {
          text-align: center;
        }
      }

      h1 {
        font-weight: 600;
      }

      h2 {
        font-weight: 400;
      }
    }
  }

  &-content {
    max-width: 40rem;
    margin: 3rem 0 0 8rem;

    @media (max-width: 500px) {
      & {
        margin: 3rem 0 0 0rem;
      }
    }

    p {
      font-size: 1.5rem;
      font-weight: 300;
      margin: 2rem 0;

      span {
        font-weight: 400;
      }
    }
  }
}

@media (min-width: 300px) and (max-width: 760px) {
  .content-pos {
    min-height: 100vh;
    background-image: url('/img/010_dash-8.png');
    background-position: center 115%;
    background-size: 115%;
    align-items: flex-start;
    justify-content: center;

    .pos {
      &-header {
        flex-direction: column;
        text-align: center;
        line-height: 1;
        &__logo {
          margin-right: 0;
        }
      }

      &-content {
        margin: auto;
        text-align: center;

        br {
          display: none;
        }
      }
    }
  }
}
