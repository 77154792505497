@import './../../../vars.scss';

.flex {
  display: flex;
}

.section-withBeluga {
  min-height: 100vh;
  width: 100vw;
  background-image: url('/img/013_dash.png'), url('/img/016_Circulo.png');
  background-position: left -23rem center, top -6rem left 10rem;
  background-repeat: no-repeat;
  background-size: 75%, 40%;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;

  .content {
    width: 50rem;
    flex-direction: column;

    &-header {
      display: flex;
      &__logo {
        width: 10rem;
        padding-top: 2rem;
  
        img {
          max-width: 7rem;
          width: 100%;
        }
      }

      &__title {
        max-width: 35rem;
  
        h1 {
          font-weight: 300;
          font-size: 2.5rem;
        }
  
        span {
          font-weight: 600;
        }
      }
    }

    p {
      font-size: 1.5rem;
      max-width: 38rem;
    }

    &-description {
      font-weight: 300;
      padding-left: 9.5rem;
    }

    &-comision {
      font-weight: 300;
      margin: 2.5rem 0;
      p {
        padding: 0;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
    }

    &-payments {
      font-weight: 300;
      .table {
        justify-content: space-between;
        max-width: 40rem;
      }

      p {
        padding: 0;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 760px) {
  .section-withBeluga {
    background-size: 110% , 50%;
    background-position: 120% 120%, 95% 85%;
    align-items: flex-start;
    padding: 2rem;
    .content {
      margin: 4rem auto 0 auto;
      padding: 0 2rem;
      &-header {
        flex-direction: column;
        text-align: center;

        .auto {
          margin: auto;
        }

        &__logo {
          text-align: center;
        }
      }

      &-description {
        padding-left: 0;

        p {
          margin: 1rem auto;
        }
      }
    }
  }
}

@media (min-width: 760px) and (max-width: 1024px) {
  .section-withBeluga {
    background-size: 100%, 40%;
    background-position: center 130%, 99% 72%;
    align-items: flex-start;
    .content {
      margin: 4rem auto 0 auto;
      padding: 0 2rem;
      &-header {
        flex-direction: column;
        text-align: center;

        .auto {
          margin: auto;
        }

        &__logo {
          text-align: center;
        }
      }

      &-description {
        padding-left: 0;

        p {
          margin: 1rem auto;
        }
      }
    }
  }
}

@media (min-width: 1025px) {
  .section-withBeluga {
    background-size: 70%, 30%;
    background-position: left -23rem top 4rem, 15% 0%;
  }
}

@media (min-width: 1600px) {
  .section-withBeluga {
    background-image: url('/img/013_dash.png'), url('/img/016_Circulo.png');
    background-size: 70%, 35%, 55%;
    background-position: left -23rem top 4rem, 20% 0%;
  }
}

@media (min-width: 1920px) {
  .section-withBeluga {
    background-position: left -23rem center, top left 25%;
    background-size: 70%, 30%;
  }
}

@media (min-width: 2256px) {
  .section-withBeluga {
    background-position: left -23rem center, top -6rem left 10rem;
    background-size: 70%, 35%;
  }
}

@media (min-width: 3200px) {
  .section-withBeluga {
    background-position: left -23rem center, top 20% left 20%;
    background-size: 70%, 35%;
  }
}
