@import './../../../vars.scss';


.content-demo {
  max-width: 100vw;
  background-image: url('/img/005_ilustracion.png');
  background-position: right 4rem bottom 10rem;
  background-repeat: no-repeat;
  background-size: 40%;
}

.info {
  padding: 13rem 8rem;
  .flex {
    display: flex;
    flex-direction: row;
  }

  &-header {
    line-height: .5;
    align-items: center;
    
    &__logo {
      margin-right: 4rem;

      img {
        width: 7rem;
      }
    }

    &__title {
      font-size: 1.7rem;

      h1 {
        font-weight: 600;
      }

      h2 {
        font-weight: 400;
      }
    }
  }

  &-content {
    margin: 2rem 0 0 11rem;

    p:nth-child(2) {
      margin: 2rem 0;
    }

    p {
      font-size: 1.5rem;
      font-weight: 300;
      max-width: 42rem;

      span {
        font-weight: 600;
      }
    }
  }

  .cards {
    align-items: center;

    img:first-child {
      width: 4rem;
    }

    img:nth-child(2) {
      width: 6rem;
    }

    img:nth-child(3) {
      width: 5rem;
      margin-right: 2.5rem;
    }

    img:nth-child(4) {
      width: 4.5rem;
    }

    img:not(:first-child):not(:last-child):nth-child(even) {
      margin: 0 2.5rem;
    }
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .content-demo {
    background-image: none;
    padding: 3rem 2rem;

    .info {
      padding: 0;
      
      .flex {
        flex-direction: column;
      }

      &-header {
        text-align: center;
        margin-bottom: 2rem;

        &__logo {
          margin-right: 0 !important;
        }
      }

      &-content {
        margin: auto !important;
        text-align: center;
    
        p {
          margin: auto;

          br { display:  none;}
        }

        p:nth-child(2) {
          margin: 2rem auto;
        }
      }

      .responsive {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      .cards {
        margin: 2.5rem auto;
        max-width: 40rem;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1920px) {
  .content-demo {
    background-position: right -2rem top;
    background-size: 40%;
    .info {
      padding: 8rem 4rem;
      &-header {
        &__logo {
          margin-right: 4rem;
          img {
            width: 6rem;
          }
        }
        &__title {
          font-size: 1.2rem;
        }
      }

      &-content {
        margin: 2rem 0 0 10rem;
        p {
          font-size: 1.3rem;
          font-weight: 300;
          max-width: 35rem;
          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media (min-width: 2150px){
  .content-demo {
    background-position: right 4rem bottom 12rem;
    background-size: 40%;
    max-width: 100rem;
    .info {
      margin: auto;
      padding: 10rem 0;
    }
  }
}
