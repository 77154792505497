@import './../../../vars.scss';

.MuiFab-root {
  box-shadow: none !important;
}

.MuiDrawer-paperAnchorRight {
  background-color: $blue-espiral !important;
}

.container-slider-menu {
  .MuiAppBar-colorPrimary {
    background-color: $blue-espiral !important;
    .MuiToolbar-root {
      padding: 15px 0 20px 0;
      min-height: auto !important;
    }
  }
  
  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }
  
  .image-logo {
    height: 3.1em;
  }
}

.container-menu {
  padding: 2rem 0;
  width: 23rem;
  max-width: 25rem;
  text-align: center;

  .container-logo {
    height: 3.5em;
    margin-bottom: 1.5rem;
  }

  .container-bts {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 6px;  

    // .btn-menu {
    //   font-weight: 200;
    //   &:last-child {
    //     margin-right: 0;
    //   }
    //   .MuiButton-root {
    //     font-size: inherit;
    //     font-weight: inherit;
    //     font-family: inherit;
    //     text-transform: inherit;
    //   }
    // }
  }

  .container-btn-flags {
    .icon-flag {
      width: 1.7em;
    }
  }

  &.mobile {
    font-size: 16px;
    .container-bts {
      display: block;
      .btn-menu {
        margin: 0;
        & > .MuiButton-root {
          width: 100%;
        }
      }
    }
    .container-btn-flags {
      text-align: center;
      .icon-flag {
        width: 3.7em;
      }
    }
  }
}

.btn-icon-menu {
  background-color: transparent !important;
  position: fixed !important;
  right: 3rem;
  top: 3rem;
  z-index: 10;
  width: 4em !important;
  height: 4em !important;
  min-height: auto !important;
  min-width: auto !important;
  padding: .7em !important;
  box-sizing: content-box !important;
}

.icon-show-menu {
  width: 100%;
  max-width: 2rem;
}

@media (max-width: 1024px) {
  .container-menu {
    display: block;
    padding: .5em;
  }
}

@media (max-width: $sm) {
  .container-menu {
    padding: 2.5rem 2rem 2rem;
  }

  .btn-icon-menu {
    top: 3%;
    width: 3em;
    height: 3em;
  }
}