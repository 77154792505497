@import './../../../vars.scss';

.gradient {
  background-color: $blue-espiral;
  background-image: linear-gradient(110deg, $blue-espiral 80%, $green-espiral 135%);
}

.section-ecommerce {
  padding: 4em 0;
  max-height: 95vh;

  .container-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    align-items: center;

    @media (max-width: 1024px) {
      & {
        grid-template-columns: 100%;
        .grid-a {
          order: 2;

          .compu-container {
            overflow: hidden;
            position: absolute;
            max-width: 80vw;
          }

          .img-compu {
            width: 100%;
            position: relative;
            top: 4rem;
            left: 0;
          }
        }
        .grid-b {
          order: 1;
        }
      }
    }

    .compu-container {
      overflow: hidden;
      position: absolute;
      max-width: 80vw;
    }

    .img-compu {
      width: 140%;
      position: relative;
      top: 4rem;
      left: -15rem;
    }
  }

  .content {
    color: white;

    &-title {
      max-width: 35rem;

      h1 {
        font-weight: 600;
        font-size: 2.5rem;
      }

      span {
        font-weight: 300;
      }
    }

    &-stores {
      margin: 3.5rem 0;
      img {
        max-width: 10rem;
        &:first-child {
          margin-right: 3rem;
        }
      }
    }

    p {
      font-size: 1.5rem;
      max-width: 35rem;

      span {
        font-weight: 600;
      }
    }

    &-description {
      font-weight: 300;
    }

    &-comision {
      font-weight: 300;
      margin: 2.5rem 0;
      p {
        padding: 0;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
    }

    &-payments {
      font-weight: 300;
      .table {
        justify-content: space-between;
        max-width: 40rem;
      }

      p {
        padding: 0;
        margin: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
    }
  }
}

// @media (max-width: 760px) {
//   .section-ecommerce {
//     min-height: 75vh !important;
//     background-position: center 88% !important;
//     padding-top: 2em;
//     align-items: flex-start;
//     justify-content: center;
//   }
// }

// @media (max-width: 1024px) {
//   .section-ecommerce {
//     min-height: 92vh;
//     background-position: center 87%;
//     padding-top: 5em;
//     align-items: flex-start;
//     justify-content: center;

//     .content {
//       width: 80%;
//       color: white;
//     }
//   }
// }

// @media (min-width: 1025px) {
//   .section-ecommerce {
//     min-height: 95vh;
//     background-size: 69%;
//     background-position: -63% center;
//   }
// }

// @media (min-width: 1025px) {
//   .section-ecommerce {
//     min-height: 85vh;
//   }
// }

// @media (min-width: 2560px) {
//   .section-ecommerce {
//     min-height: 85vh;
//     background-size: 65%;
//     background-position:  -10% center;
//   }
// }
