@import './../../../../vars.scss';

ul {
    margin: 0;
    padding: 0;
}

li {
    width: 100%;
    padding: 1rem 0 1rem 2rem;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
    background-size: 225%;
    transition: all .4s;

    &:hover,
    &:active {
        background-position: 100%;
    }
}

.image-logo {
    animation: scaleIn .4s ease-in-out;
}

.nav {
    overflow: scroll;
    &__list {
        display: flex;
        list-style: none;
        flex-direction: column;
        text-align: left;
        animation: fadeIn 1s ease-in-out;
    }
    
    &__item {
        width: 100%;
        margin: .5rem 0;

        &:hover a {
            color: $light-blue-espiral;
        }
    }

    &__link {
        &:link,
        &:visited {
            font-size: 1rem;
            font-weight: 400;
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
        }

        &:hover,
        &:active {
            background-position: 100%;
            color: $light-blue-espiral;
            transform: translateX(1rem);
        }
        
    }
}

.buttons {
    margin-top: 2rem;
    .btn-menu {
        color: $light-blue-espiral !important;
        font-size: 1rem !important;
        font-weight: 500;
        border: 5px solid $light-blue-espiral !important;
        padding: 0.1em 2.3em;
        border-radius: 10px;
        transition: all 250ms;
    
        &:hover {
            border: 5px solid white !important;
            background-color: white !important;
            color: $light-blue-espiral;
        }
    }

    .call {
        margin-top: 2rem;
    }
}

a {
    &:visited,
    &:link {
        text-decoration: none;
    }
}

@keyframes scaleIn {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
