@import './../../../vars.scss';

.wl-gradient {
    background-color: $blue-espiral;
    background-image: linear-gradient(110deg, $blue-espiral 45%, $green-espiral 90%);
}

.content-wl {
    min-height: 100vh;
    padding: 4em 0px;
    max-width: 95rem;
    width: 100vw;
    background-image: url('/img/038computer-8.png');
    background-position: top 237% right;
    background-size: 57%;
    background-repeat: no-repeat;
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.wl {
    &-header {        
        align-items: center;

        &__title {
            font-size: 1.7rem;

            h1 {
                font-weight: 400;
                color: white;
                max-width: 38rem;
                span {
                    font-weight: 600;
                }
            }
        }
    }

    &-content {
        max-width: 40rem;
        margin-top: 4rem;

        h2 {
            margin: 0;
        }

        p {
            font-size: 1.5rem;
            font-weight: 300;
            margin: 0  0 2rem 0;
        }

        img {
            margin-top: 1rem;
            width: 9.5rem;
        }
    }
}

@media (min-width: 320px) and (max-width: 1024px) {
    .content-wl {
        background-image: none;
        justify-content: center;
        min-height: 75Vh;
        .wl {
            padding-left: 2rem;
        }
    }
}

@media (min-width: 1280px) {
    .content-wl {
        background-position: right 50%;
        background-size: 55%;
    }

    .wl {
        margin-left: 4em;

        &-header {
            &__title {
                font-size: 1.4rem;
            }
        }
    }
}

@media (min-width: 1600px) {
    .content-wl {
        background-position: right 50%;
    }

    .wl {
        margin-left: 5em;
    }
}

@media (min-width: 1920px) {
    .content-wl {
        max-width: 105rem;
        background-position: right 50%;
        background-size: 55%;
        padding-left: 5em;
    }

    .wl {
        margin-left: 5em;
    }
}

@media (min-width: 3200px) {
    .content-wl {
        background-position: 100% 10%;
        background-size: 65%;
        min-height: 85vh;
    }

    .wl {
        max-height: 65vh;
    }
}
