@import './../../../vars.scss';

.trade-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5em 0;
}

.content-trademark {
  text-align: center;

  h1 {
    color: #0C6DA8;
  }

  .cards {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 6rem;
    }

    &-cert {
      img:first-child,
      img:nth-child(2),
      img:nth-child(10) {
        width: 4rem;
      }

      img:nth-child(8),
      img:nth-child(9) {
        width: 5rem;
      }

      img:nth-child(6) {
        width: 8rem;
      }

      img:nth-child(5),
      img:nth-child(7) {
        width: 10rem;
      }
    }

    &-press {

    }
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 400;
    margin: 2rem;

    span {
      font-weight: 300;
    }
  }
}

@media (max-width: 1024px) {
  .trade-container {
    background-image: none;
    .content-trademark {
      .cards {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0 1.2rem;

        img {
          margin: 2rem 0;
        }
        
        img:nth-child(even) {
          margin: 0 2rem;
        }

        img:nth-child(4) {
          margin-left: 1.8rem;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .trade-container {
    .content-trademark {
      .cards {
        img:not(:first-child):nth-child(even) {
          margin: 2rem 3.5rem;
        }
      }
    }
  }
}
