@import './../../../vars.scss';

.section-header {
  background-image: url('/img/024_banner.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-background {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: black;
  }

  .header {
    &__logo {
      width: 100%;
      max-width: 20rem;
      display: block;
      position: absolute;
      top: 3rem;
      left: 3rem;
    }

    &__language {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-top: 0;
        cursor: pointer;
      }

      p:nth-child(2) {
        border-left: 1px solid black;
        margin-left: 15px;
        padding-left: 15px;
      }

      .flag-lang {
        width: 50px;
      }
    }

    &__phone {
      margin-top: 0;
      font-size: 1.2rem;
      font-weight: 600;
      position: absolute;
      top: 3.6rem;
      right: 8rem;
      a {
        color: #9C0AE8;
      }
    }

    &__button {
      margin-top: 6rem
    }
  }
  
  .section-text-center {
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    color: #0C6DA8;
    font-size: 5.3em;
    line-height: 0;

    .payments {
      font-size: .40em;
    }
  }
}

@media(max-width: 380px) {
  .section-background {
    .header {
      &__logo {
        max-width: 15rem;
        top: 3rem;
      }

      &__phone {       
        top: 3rem !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .section-header {
    background-image: url('/img/024_banner.jpg');
    background-position: center center;
    background-size: cover;

    .header {
      &__logo {
        top: 3rem;
        left: 2rem;
      }
  
      &__phone {
        font-size: 1.2rem;
        font-weight: 600;
        position: absolute;
        top: 3.2rem;
        right: 8rem;
      }
  
      &__button {
        margin: 6rem 0 0 -2.5rem;
      }
    }
    
    .section-text-center {
      margin-left: -2.5rem;
      color: white;
      font-size: 3.3em;
      line-height: 0;
  
      .payments {
        text-align: left;
        font-size: .40em;
      }
    }
  }
}
